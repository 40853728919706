/* ---------- Resize Div CSS  ----------- */

.Resizer.horizontal {
  border-top: 1px solid var(--resizeBg);
  border-bottom: 1px solid var(--resizeBg);
  height: 4px;
  cursor: s-resize;
  transition: 0.2s;
}

.Resizer.vertical {
  border-left: 1px solid var(--resizeBg);
  border-right: 1px solid var(--resizeBg);
  width: 4px;
  cursor: e-resize;
  transition: 0.2s;
}

.Resizer.horizontal:hover,
.Resizer.vertical:hover {
  background: var(--resizeBg);
}

.Pane.horizontal.Pane2 {
  overflow: hidden;
  padding-bottom: 25px;
}

.Pane {
  overflow: auto;
}

.Pane.vertical {
  padding: 0 10px;
  margin: 10px 0;
}

.resizable-x,
.resizable-y {
  display: flex;
  /* overflow: hidden; */
}

.resizable-y {
  flex-direction: column;
}

.resizer-x,
.resizer-y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resizer-x {
  z-index: 2;
  cursor: col-resize;
}

.resizer-y {
  z-index: 2;
  cursor: row-resize;
  flex-direction: column;
}

.resizer-x::before,
.resizer-x::after,
.resizer-y::before,
.resizer-y::after {
  content: "";
  margin: 1px;
  background: gray;
}

.resizer-x::before,
.resizer-x::after {
  width: 1px;
  height: 20px;
}

.resizer-y::before,
.resizer-y::after {
  width: 20px;
  height: 1px;
}

/* -------- Contact Right Click --------- */

nav.react-contextmenu {
  min-width: 180px;
  border-radius: 4px;
  background-color: rgb(0 0 0 / 74%);
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  overflow: hidden;
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #000;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 13px;
  display: block;
  text-decoration: none;
  padding: 5px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.react-contextmenu-item span {
  margin-left: 10px;
}

/* -------- Profile DropDown Css -------- */

.profileDropdown {
  color: #fff;
  padding: 2px 5px;
  transition: 1s;
  border-radius: 3px;
  cursor: pointer;
}

.profileDropdown:hover,
.profileActive {
  background: #fff;
  color: var(--mainColor);
}

a {
  text-decoration: none;
  transition: 1s;
}

a:hover {
  text-decoration: underline;
  color: var(--mainColor) !important;
}

/* -------- Recent Activity Css -------- */

.feed-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: -5.5px;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid var(--mainColor);
}

.qr__imag {
  display: flex;
  justify-content: center;
}

.qr__imag img {
  width: 225px;
}

.qr__imag .security-shield,
.qr__imag img {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 #63636333;
}

.qr__imag .security-shield {
  padding: 20px 50px;
  text-align: center;
}

.qr__imag .security-shield i {
  font-size: 150px;
  color: var(--accent-200);
}

/* -------- Full Screen Mode CSS -------- */

.code-list-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white;
}

/* -------- Add new Document & pdf editor-------- */

.custom-cursor {
  cursor: grab;
}

.custom-cursor:active {
  cursor: grabbing;
}

.card_hover:hover,
.card_hover.activee {
  border: 1px solid var(--mainColor) !important;
  color: var(--mainColor);
  transition: 1s;
  font-weight: bold;
}

.allBorder:hover {
  border: 2px solid var(--mainColor) !important;
  transition: 1s;
}